<template>
  <v-row class="d-flex justify-center align-center">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      top
      right
      color="success"
      timeout="2000"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="snackbar = false">
          <f-icon icon="times-circle" />
        </v-btn>
      </template>
    </v-snackbar>

    <v-col cols="8">
      <v-card flat>
        <v-card-title>Edit account information</v-card-title>
        <v-card-text v-if="!$store.state.loading">
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-end">
                <v-avatar tile rounded="md" size="160" class="white darken-4">
                  <img
                    v-if="imageUrl != null && imageUrl != ''"
                    :src="imageUrl"
                    alt="alt"
                  />
                  <v-icon size="70" v-else>mdi-account</v-icon>
                </v-avatar>
                <div class="d-flex align-start flex-column mx-5">
                  <v-btn
                    depressed
                    :loading="isSelecting"
                    @click="onButtonClick"
                    color="primary"
                  >
                    <v-icon class="mr-2">mdi-file-account</v-icon>
                    {{ buttonText }}
                  </v-btn>
                  <div>Acceptable formats: jpg, png only</div>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChanged"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <h2>ACCOUNT INFORMATION</h2>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="inputs.fullName"
                    :label="$t('name')"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Name is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="inputs.userName"
                    :label="$t('username')"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Username is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    v-model="inputs.email"
                    :label="$t('email')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-btn color="success" large @click="updateUser">
                      <v-icon class="mr-2">mdi-content-save-all-outline</v-icon>
                      Save
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-2"></v-divider>
            </v-col>
            <v-col cols="12">
              <h2>CHANGE PASSOWRD</h2>
              <v-row class="mt-2">
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="password.oldPassword"
                    label="Current Password"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Current Password is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    v-model="password.password"
                    label="New Password"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'New Password is required']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12">
                <div class="d-flex justify-end">
                  <v-btn color="success" large @click="updatePassord">
                    <v-icon class="mr-2">mdi-content-save-all-outline</v-icon>
                    Update
                  </v-btn>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="$store.state.loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Settings",
  data() {
    return {
      user: {},
      snackbar: false,
      snackbarText: "",
      password: {
        oldPassword: "",
        password: "",
      },
      file: null,
      buttonText: "Select Image",
      isSelecting: false,
      inputs: {
        fullName: "",
        userName: "",
        email: "",
        phoneNumber: "",
        imageUrl: "",
      },
      imageUrl: "",
    };
  },
  methods: {
    updateUser() {
      this.$store.commit("setLoading", true);

      this.$store.dispatch("uploadFile", this.file).then((url) => {
        if (typeof url === "string") {
          this.inputs.imageUrl = url;
        }
        if (typeof this.inputs.imageUrl === "object") {
          this.inputs.imageUrl = "";
        }
        this.inputs.imageUrl = this.inputs.imageUrl.replace(
          "https://api.dev.erp.scode.tech/",
          ""
        );
        this.$http
          .put("/auth/updateProfile", this.inputs)
          .then(() => {
            this.$store.commit("setLoading", false);

            this.snackbarText = "Profile updated successfully";
            this.snackbar = true;
          })
          .catch((e) => {
            
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit(
              "UPDATE_SNACKBAR_MESSAGE",
              e.response.data.message +
                "<br />" +
                typeof e.response.data.errors ==
                "array"
                ? e.response.data.errors.join(" ")
                : ""
            );
          })
          .finally(() => {
            this.$store.commit("setLoading", false);
          });
      });
    },
    updatePassord() {
      this.$store.commit("setLoading", true);
      this.password.confirmPassword = this.password.password;
      this.$http
        .post("/Auth/changeMyPassword", this.password)
        .then(() => {
          this.$store.commit("setLoading", false);

          this.snackbarText = "Password updated successfully";
          this.snackbar = true;
        })
        .catch((e) => {
          

          this.$store.commit("setLoading", false);

          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message +
              "<br />" +
              e.response.data.errors.join(" ")
          );
          // this.$store.commit("UPDATE_SNACKBAR", true);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.buttonText = this.file.name;
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
    },
  },
  created() {
    this.$store.commit("setLoading", true);
    this.$http.get("/users/" + this.$store.state.userInfo.userData.id).then((res) => {
      this.user = res.data.result;
      Object.keys(this.inputs).forEach((key) => {
        this.inputs[key] = this.user[key];
      });
      this.imageUrl = this.user.imageUrl;
      this.$store.commit("setLoading", false);
    });
  },
};
</script>